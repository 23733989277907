import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Bjørn, you are the developer of Trailguide and you are also a keen mountain biker. When did you use Trailguide the last time to explore a new region?`}</h4>
    <p>{`Last weekend when we celebrated our friends 50th birthday. Our gift to him was
to take him on a three day mountain bike trip in Felle in Telemark,
Norway. Neither of us knew the area and we used Trailguide as a digital guide.
We found amazing mountain biking on huge rock slabs and beautiful needle covered
trails in the woods.`}</p>
    <Image src="/news/bikingprogrammer.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Last week Trailguide published a new version with a completely new design. How long did the development take?`}</h4>
    <p>{`It started with a quick sketch in May of last year. However, there were a lot
of other important issues on the todo-list first, so I was not able to pick it
up again before April this year.`}</p>
    <br />
    <p>{`I quickly developed a first prototype the
first week and deployed it to our test-server so a small test-team could start
testing it. Then another three weeks to adapt the design according to the
feedback and finish the new features.`}</p>
    <Image src="/news/sketchfrontend.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`What was the idea behind the new design of Trailguide?`}</h4>
    <p>{`I felt there was quite a few problems with the old design. Some of the content
and features felt slightly out of place, the design was not very flexible when
you wanted to add new features, and the current design wasn’t really ready for
the increasing amount of content we were getting.`}</p>
    <br />
    <p>{`It's difficult to implement a large set of desired features and still make
the app look good while providing a nice user experience.`}</p>
    <br />
    <p>{`The new design is more search-oriented with a prominent search bar and easily
accessible trail filter visible at the top of the screen. This makes it easy
to find the right content wherever you are in the app. I also wanted the phone
and desktop version to have the same look and I feel we have succeeded here.`}</p>
    <Image src="/news/StartScreenEuropeT.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`What where the most difficult tasks during the development process and how did you solve them?`}</h4>
    <p>{`(Laughs) Good question. There were no technically difficult tasks, but when I
released the prototype for testing I quickly saw that some users didn’t use
the front end as I expected. So the most difficult task was to adapt the
user interface to the feedback of the testers.`}</p>
    <br />
    <p>{`Using the app should feel natural. Someone who doesn’t know the app as good
as the developer should be able to use it intuitively. I learned a lot from
this feedback and was able to improve the design and get rid of the problems.`}</p>
    <br />
    <h4>{`One word that describes the new front end?`}</h4>
    <p>{`Search-focused. Ok, that was two words (laughs). You can also say
“aaamaaazing” (laughs again).`}</p>
    <Image src="/news/process.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`The app worked very well before. Why didn’t you leave it the way it was?`}</h4>
    <p>{`Because I was hitting limitations when I wanted to add more functionality.
Also, with the increasing amount of content, we reached a point where it became
harded to use the app and find what you wanted. Our thoughts of how Trailguide
should look and work are constantly evolving, hopefully for the better.`}</p>
    <br />
    <h4>{`Where do you get the ideas to develop Trailguide?`}</h4>
    <p>{`Often when I'm out biking and using Trailguide myself, or when biking with
friends and observing their use of the app. I get to see the problems or
limitations they hit while trying to navigate, find new trails or just
navigate around the user interface of the app. I also get feedback from friends
and facebook contacts about problems and requests for improvements and
desired features.`}</p>
    <Image src="/news/trailguidephone1.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`You started developing Trailguide in 2013, what is your motivation to constantly work on the software and improving it?`}</h4>
    <p>{`Initially it was because I couldn't find any trails myself and there was no
tool like Trailguide. So I wanted to make one. And then of course I wanted to
make it better. At some point it becomes your baby and you become super
emotionally involved with it (and take all critisism personally).`}</p>
    <br />
    <p>{`The main motivation right now is that I want Trailguide to become the best
version of itself possible. I absolutely love the development process. I get
up every morning and want to continue working on it, and can hardly stop
thinking about it at night and look forward to start again the next morning.`}</p>
    <br />
    <p>{`And then of course, there are always new and improved technology released which
enable you to add new cool features or improve the internal quality of the
software. It is a never-ending cycle. You always want to use that new shiny
thing, just as with bikes and bike equipment.`}</p>
    <br />
    <h4>{`What are you working on next?`}</h4>
    <p>{`I think the UX (User Experience) framework for the future of Trailguide is now
in a good shape. The next goals are to implement new features to make it even
better to find and share the best trails and destinations.`}</p>
    <br />
    <p>{`One of those features is to implement a global search to find locations and
all types of content stored in Trailguide. But of course the todo list is quite
long so you can look forward to lots of exciting things!`}</p>
    <Image src="/news/bjornoffice.jpg" className="w-3/4 mx-auto my-12" mdxType="Image">
  (waiting for that important investor call)
    </Image>
    <br />
    <h4>{`Become a Trailguide Patreon`}</h4>
    <p>{`If you enjoy the use of Trailguide you can now support us and
`}<a parentName="p" {...{
        "href": "https://www.patreon.com/trailguideapp?fan_landing=true"
      }}>{`become a Trailguide Patreon`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      